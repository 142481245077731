import React from "react";
import Image from "../image";
import cn from "classnames";

export interface Props {
  isInstagramOnly?: boolean;
  className?: string;
}

export default function SocialMediaIconGroup(props: Props) {
  const { isInstagramOnly, className } = props;
  return (
    <div className={cn('social-media-icons', className)}>
      <a className="ig" href="https://www.instagram.com/playwhathk/" target="_blank" style={{ display: "flex" }} rel="noreferrer" data-click-label="footer_instagram" aria-label="Visit our Instagram">
        <Image
          className="svg-icon"
          width={28}
          height={28}
          url="/icons/logo-instagram.svg"
          alt=""
        />
      </a>
      {!isInstagramOnly && (
        <>
          <a className="fb" href="https://www.facebook.com/PlaywhatHK/" target="_blank" style={{ display: "flex" }} rel="noreferrer" data-click-label="footer_facebook" aria-label="Visit our Facebook page">
            <Image
              className="svg-icon"
              width={28}
              height={28}
              url="/icons/logo-facebook.svg"
              alt=""
            />
          </a>
          {/* <a className="mewe" href="https://mewe.com/p/playwhat/" target="_blank" style={{ display: "flex" }} rel="noreferrer" data-click-label="footer_mewe" aria-label="Visit our MeWe page">
            <Image
              className="svg-icon"
              height={20}
              width={64}
              url="/icons/logo-mewe.png"
              objectFit='contain'
              alt=""
            />
          </a> */}
        </>
      )}
    </div>
  );
}
