import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { languageShortNames } from "../../utilities/translations/config";
import React, { useCallback } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const router = useRouter();
  const { pathname } = router;

  const languageList = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in languageShortNames) {
    if (i18n.language !== key) {
      languageList.push({
        label: languageShortNames[key],
        value: key,
      });
    }
  }

  const changeLang = useCallback((lang: string) => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 90); // 90 days
    cookies.set("NEXT_LOCALE", lang, { path: "/", expires: expiryDate });
    const isEventDetailPage = pathname === '/events/[id]';
    const targetPath = isEventDetailPage ? '/' : pathname;
    router.push(targetPath, targetPath, { locale: lang });
  }, [pathname])

  return (
    <div className="language-switcher">
      {languageList &&
        languageList.map((item) => (
          <button
            type="button"
            key={item.value}
            onClick={() => changeLang(item.value)}
            aria-label="change language"
            data-click-label="change_lang"
            data-click-value={item.value}
          >
            {item.label}
          </button>
        ))}
    </div>
  );
}
