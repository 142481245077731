export const defaultLocale = 'zh';

// export const locales = ['en', 'zh', 'cn'];
export const locales = ['en', 'zh'];

export const languageNames = {
  en: 'English',
  zh: '繁體中文',
  // cn: '简体中文',
};

export const languageShortNames = {
  en: 'Eng',
  zh: '繁',
  // cn: '简',
};
