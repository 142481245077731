import { atom, DefaultValue, selector } from "recoil";
import {
  BookmarkItem, MyBookMarkItem,
  MyEventItem,
  UserInfo
} from "../types";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const userInfoAtom = atom<UserInfo | undefined>({
  key: "userInfoAtom",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const myEventsAtom = atom<MyEventItem[]>({
  key: "myEventsAtom",
  default: [],
});

export const myBookMarksAtom = atom<MyBookMarkItem[]>({
  key: "myBookMarksAtom",
  default: [],
});

export const bookmarkSelector = selector<BookmarkItem[]>({
  key: "bookmarkSelector",
  get: ({ get }) => {
    const userInfo = get(userInfoAtom);
    if (userInfo) return userInfo.bookmarks;
    return [];
  },
  set: ({ get, set }, bookmarks) => {
    const userInfo = get(userInfoAtom);
    if (!(bookmarks instanceof DefaultValue)) {
      set(userInfoAtom, {
        ...userInfo,
        bookmarks,
      });
    }
  },
});
