import { useAuthUser } from "next-firebase-auth";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { userInfoAtom } from "../recoil/user";
import { getUserInfo } from "../services/user";

export default function useUserInfo() {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const user = useAuthUser();
  const hasSignedIn = !!user?.id;
  const id = user?.id;

  const refreshUserInfo = useCallback(async () => {
    if (hasSignedIn && user?.getIdToken) {
      const idToken = await user.getIdToken();
      const userInfo = await getUserInfo(idToken);
      setUserInfo({
        id,
        updatedAt: Date.now(),
        ...userInfo,
      });
    }
  }, [hasSignedIn, id, setUserInfo]);

  const resetUserInfo = useCallback(() => {
    setUserInfo(undefined);
  }, [setUserInfo]);

  return {
    refreshUserInfo,
    userInfo,
    resetUserInfo,
  };
}
