import { useAuthUser } from "next-firebase-auth";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import React from "react";
import useUserInfo from "../../hooks/useUserInfo";
import { isProd } from "../../utilities/common";
import { getBreadcrumbTrailSchema } from "../../utilities/schema";
// import LayoutNotification from './notification';
import LayoutFooter from "./footer";
import LayoutHeader from "./header";
declare global {
  interface Window {
    adsbygoogle: any;
  }
}

interface Props {
  isHomepage?: boolean;
  buildVersion?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  keywords?: string;
  page?: "event-detail" | "other";
}

function Layout(props: React.PropsWithChildren<Props>) {
  const {
    children,
    buildVersion,
    title,
    description,
    imageUrl,
    isHomepage,
    keywords,
    page,
  } = props;
  const user = useAuthUser();
  const { userInfo, refreshUserInfo, resetUserInfo } = useUserInfo();
  const hasSignedIn = !!user?.id;
  const id = user?.id;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const pageTitle = title || t("siteTitle");
  const pageDescription = description || t("siteDescription");
  const pageKeywords = keywords || t("siteKeywords");
  const [pagePath, setPagePath] = React.useState(null);
  const router = useRouter();
  const breadcrumbSchema = getBreadcrumbTrailSchema(router, t, title);
  React.useEffect(() => setPagePath(window.location.href), []);

  React.useEffect(() => {
    console.log("[Layout] refresh userInfo", {
      hasSignedIn,
      clientInitialized: user?.clientInitialized,
      id,
      userInfoId: userInfo?.id,
      updatedAt: userInfo?.updatedAt,
    });
    if (user?.clientInitialized) {
      if (hasSignedIn) {
        if (
          !userInfo?.id ||
          id !== userInfo?.id ||
          Date.now() - (userInfo?.updatedAt || 0) > 1000 * 60 * 15 // 15-minutes
        ) {
          refreshUserInfo();
          return;
        }
        return;
      }
      resetUserInfo();
    }
  }, [
    hasSignedIn,
    refreshUserInfo,
    resetUserInfo,
    user?.clientInitialized,
    id,
    userInfo?.id,
    userInfo?.updatedAt,
  ]);

  return (
    <div>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        {/* <link
          href={`https://fonts.googleapis.com/css2?${fontFamilyString}&display=swap`}
          rel="stylesheet"
        /> */}
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&family=Noto+Sans+HK:wght@300;400;700&family=Merriweather:wght@300;400&display=swap"
          rel="stylesheet"
        />

        <meta property="og:type" content="website" />
        {pagePath && <meta property="og:url" content={pagePath} />}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta name="og:title" content={pageTitle} />
        {imageUrl && <meta property="og:image" content={imageUrl} />}
        {!isProd && <meta name="robots" content="noindex" />}
      </Head>
      {/* Script */}
      <Script
        id="google-adsense"
        data-ad-client="pub-8113805660155157"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" // Adsense
        strategy="lazyOnload"
      />
      <Script id="gtm" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');` // GTM
        }
      </Script>
      <Script id='facebook-pixel' strategy="afterInteractive">
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '757557848751958');
        fbq('track', 'PageView');`}
      </Script>
      <noscript><img height="1" width="1" style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=757557848751958&ev=PageView&noscript=1"
      /></noscript>
      {/* <LayoutNotification /> */}
      <LayoutHeader isHomepage={isHomepage} user={user} userInfo={userInfo} />
      <main>{children}</main>
      <LayoutFooter buildVersion={buildVersion} />
      {breadcrumbSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbSchema),
          }}
        />
      )}
    </div>
  );
}

export default Layout;
