/* eslint-disable camelcase */
import moment from "moment";
import { NextRouter } from "next/router";
import { TFunction } from "next-i18next";

// https://developers.google.com/search/docs/data-types/event#region-availability

const getOrganizerSchema = (eventData: any) => {
  const { organizer } = eventData;
  if (organizer) {
    const { name, website_url } = organizer;

    const result = {
      performer: {
        "@type": "PerformingGroup",
        name,
      },
      organizer: {
        "@type": "Organization",
        name,
        url: undefined,
      },
    };

    if (website_url) {
      result.organizer.url = website_url;
    }
    return result;
  }
  return {};
};

const getVenueSchema = (eventData: any) => {
  const { venue } = eventData;
  if (!venue) return {};
  const { name, address_1, type } = venue;
  if (type === "online") {
    return {
      location: {
        "@type": "VirtualLocation",
        name,
        url: address_1,
      },
      eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
    };
  }
  const result = {
    location: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        postalCode: "00000",
        addressCountry: "HK",
        streetAddress: undefined,
      },
      name: undefined,
    },
    eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
  };
  if (address_1) {
    result.location.name = name;
    result.location.address.streetAddress = address_1;
  } else {
    result.location.address.streetAddress = name;
  }
  return result;
};

const getEventSchema = (eventData: any) => {
  try {
    const {
      title,
      start_datetime,
      end_datetime,
      gallery_images,
      enrol_type,
      enrol_link,
    } = eventData;

    const venueJson = getVenueSchema(eventData);
    const organizerJson = getOrganizerSchema(eventData);

    let eventImages = [];
    if (gallery_images) {
      if (typeof gallery_images === "string") {
        eventImages = gallery_images.split(",");
      } else {
        eventImages = gallery_images;
      }
    }

    const result = {
      "@context": "https://schema.org",
      "@type": "Event",
      name: title,
      startDate: moment(start_datetime).format(),
      endDate: moment(end_datetime).format(),
      ...venueJson,
      image: eventImages,
      // "description": "",
      offers: undefined,
      ...organizerJson,
    };

    if (enrol_type === "playwhat" || !!enrol_link) {
      const { uid, enrol_start_date, price, currency } = eventData;
      const offers = {
        "@type": "Offer",
        price,
        priceCurrency: currency,
        url: undefined,
        validFrom: undefined,
      };
      if (enrol_type === "playwhat") {
        offers.url = `https://playwhat.hk/zh/events/registration?uid=${uid}`;
      } else if (enrol_link) {
        offers.url = enrol_link;
      }
      if (enrol_start_date)
        offers.validFrom = moment(enrol_start_date).format();
      result.offers = offers;
    }
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const getBreadcrumbTrailSchema = (
  router: NextRouter,
  t: TFunction,
  pageTitle?: string
) => {
  const { pathname, locale } = router;
  // console.log(pathname);
  if (pathname.match(/\//g).length > 1) {
    const parts = pathname.split("/").filter((p) => !!p);
    const itemListElement = parts.map((p, index) => {
      const key = `${parts.slice(0, index + 1).join("/")}`;
      const isLastItem = parts.length - 1 === index;
      let name = t(`common:breadcrumb.${key}`);
      if (isLastItem) {
        if (pathname === "/events/[id]" && pageTitle) {
          name = pageTitle.replace(' | Playwhat', '');
        } else {
          name = t(`common:breadcrumb.${pathname.substring(1)}`);
        }
      }
      const path = !isLastItem
        ? `${process.env.NEXT_PUBLIC_WEBSITE_DOMAIN}/${locale}/${key}`
        : undefined;
      /* console.log("getBreadcrumbTrailSchema", {
        key,
        path,
        name,
        index,
        p,
        pathname,
        parts,
        locale,
      }); */
      return {
        "@type": "ListItem",
        position: index + 1,
        name,
        item: path
      };
    });
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: itemListElement,
    };
  }
  return undefined;
};
export default getEventSchema;
