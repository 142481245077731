import moment from "moment";

const getExcerpt = (desc) => {
  const strippedDesc = (desc || '').replace(/(<([^>]+)>)/gi, '');
  const excerpt = strippedDesc.substring(0, 200);
  return excerpt;
};

const isUrl = (link) => {
  try {
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    if (link.match(regex)) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

const isImageUrl = (link) => {
  const regex = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif))$/;
  return regex.test(link);
}

const isSocialMediaLink = (url) => {
  return isFacebookLink(url) || isYoutubeLink(url) || isInstagramLink(url);
}

const isFacebookLink = (url) => {
  const firstRegex = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/;
  const secondRegex = /home((\/)?\.[a-zA-Z0-9])?/;
  if (firstRegex.test(url) && !secondRegex.test(url)) {
    return true;
  }
  return false;
}

const isYoutubeLink = (url) => {
  const firstRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  return firstRegex.test(url);
}

const getYoutubeVideoId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}

const isInstagramLink = (url) => {
  const firstRegex = /(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)).*/;
  return firstRegex.test(url);
}

const getBuildVersion = () => moment().format('YYYYMMDD/HHmmss')

const eventArticlePlatformSet = new Set(['weekendhk', 'ulifestyle', 'nearsnake', 'hkppltravel', 'holidaysmart'])
const isEventArticlePlatform = (platform = '') => {
  const result = eventArticlePlatformSet.has(platform.toLowerCase());
  // console.log('[isEventArticlePlatform]', {platform, result});

  return result;
}

const unapprovedPlatformSet = new Set(['nearsnake', 'hkppltravel', 'eventbrite', 'timable', 'holidaysmart'])
const isUnapprovedPlatform = (platform = '') => unapprovedPlatformSet.has(platform.toLowerCase())

const isProd = process.env.VERCEL_ENV === "production" || process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
const pushAd = () => {
  try {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
const scrollToTop = (disableSmooth = false) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: disableSmooth ? undefined : "smooth",
  });
}

export { getExcerpt, isUrl, isImageUrl, isSocialMediaLink, isFacebookLink, isYoutubeLink, isInstagramLink, getYoutubeVideoId, getBuildVersion, isEventArticlePlatform, isUnapprovedPlatform, eventArticlePlatformSet, isProd, pushAd, scrollToTop };
export default getExcerpt;
